import { firestore } from "@/firebase";
import { doc, getDoc } from 'firebase/firestore';

export async function isAuthenticated() {

  const url = window.location.href;
  const urlParams = new URLSearchParams(new URL(url).search);
  console.log("url params===> ", urlParams);
  const paramValue = urlParams.get("auth"); // 'exampleValue'


  console.log("paramValue===> ", paramValue);



  try {
    const docRef = doc(firestore, 'Users', paramValue);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log('Document data:', docSnap.data());

      return docSnap.data().role.includes('ambassador dashboard')
    } else {
      console.log('No such document!');
      return false
    }
  } catch (error) {
    console.error('Error getting document:', error);
    return false
  }


}







