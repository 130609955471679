import axios from "axios";
// import moment from 'moment';

class AppService {
  baseUrl = "http://localhost:3000";
  user = {};

  async login(loginInfo, filters) {
    try {
      const response = await axios.post(`${this.baseUrl}/login`, {
        ...loginInfo,
        filters,
      });
      this.removeSpacesFromObjectKeys(response.data);
      return response.data;
    } catch (err) {
      console.error(err);
    }
  }

  async fetchAmbassadorDashboardData(params) {
    try {
      const response = await axios.get(`${this.baseUrl}/ambassador-dashboard`, {
        params: {
					...params,
          userMonitorAccount: this.user.MonitorAccount,
        },
      });
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  }

  removeSpacesFromObjectKeys(obj) {
    const newObj = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const newKey = key.replace(/\s+/g, "");
        newObj[newKey] = obj[key];
      }
    }
    this.user = newObj;
    return newObj;
  }
}

export default new AppService();
