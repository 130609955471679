<template>
  <div class="filter">
    <div class="filtIcon">
      <i class="fa-solid fa-filter"></i>
      <h2>Filters</h2>
    </div>
    <!-- parking -->
    <div class="opt">
      <div class="evnetsFilterText">
        <p ref="eventsParent">
          Events:
          <span @click="toggleDropdown">
            {{ selectedOption }} <i class="fa-solid fa-chevron-down"></i
          ></span>
        </p>
        <div v-if="showParkingsDropdown" class="newEventsDropdown">
          <ul>
            <li
              @click="selectOption('No Placard')"
              :class="{ selected: selectedOption === 'No Placard' }"
            >
              <span>
                <div>No Placard</div>
              </span>
            </li>
            <li
              @click="selectOption('Illegally Parked')"
              :class="{ selected: selectedOption === 'Illegally Parked' }"
            >
              <span>
                <div>Illegally Parked</div>
              </span>
            </li>
            <li
              @click="selectOption('Suspected Placard Misuse')"
              :class="{
                selected: selectedOption === 'Suspected Placard Misuse',
              }"
            >
              <span>
                <div>Suspected Placard Misuse</div>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <!-- state -->
      <!-- <div class="evnetsFilterText">
        <p ref="stateParent">
          State:
          <span @click="toggleStateDropdown">
            {{ selectedStateOption }} <i class="fa-solid fa-chevron-down"></i
          ></span>
        </p>
        <div v-if="showStateDropdown" class="newEventsDropdown">
          <ul>
            <li>
              <span @click="selectStateOption('California')">
                <div>California</div>
              </span>
            </li>
            <li>
              <span @click="selectStateOption('Texas')">
                <div>Texas</div>
              </span>
            </li>
            <li>
              <span @click="selectStateOption('Florida')">
                <div>Florida</div>
              </span>
            </li>
            <li>
              <span @click="selectStateOption('Hawaii')">
                <div>Hawaii</div>
              </span>
            </li>
          </ul>
        </div>
      </div> -->
      <!-- date start -->
      <div class="evnetsFilterText">
        <p ref="dateStartParent">
          Date Start:
          <span @click="toggleDateDropdown">
            {{ selectedStartDate }} <i class="fa-solid fa-chevron-down"></i
          ></span>
        </p>
        <div class="newEventsDropdowns" v-if="showDateDropdown" @click.stop>
          <div class="dateStartContainer">
            <div class="dateStart">
              <div class="dropdownSelectDate dropdownSelect">
                <div class="inner-dropdown">
                  <p>Date</p>
                  <select v-model="selectedDate">
                    <option v-for="day in days" :key="day">{{ day }}</option>
                  </select>
                </div>
              </div>
              <div class="dropdownSelectMonth dropdownSelect">
                <div class="inner-dropdown">
                  <p>Month</p>
                  <select v-model="selectedMonth">
                    <option v-for="month in months" :key="month">
                      {{ month }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="dropdownSelectYear dropdownSelect">
                <div class="inner-dropdown">
                  <p>Year</p>
                  <select v-model="selectedYear">
                    <option v-for="year in years" :key="year">
                      {{ year }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <a class="doneBtn" @click="handleDateDone" href="#">Done</a>
        </div>
      </div>
      <!-- date end  -->
      <div class="evnetsFilterText">
        <p ref="dateEndParent" class="evnetsFilterText">
          Date End:
          <span @click="toggleEndDateDropdown">
            {{ selectedEndDate }} <i class="fa-solid fa-chevron-down"></i
          ></span>
        </p>
        <div class="newEventsDropdowns" v-if="showEndDateDropdown" @click.stop>
          <div class="dateStartContainer">
            <div class="dateStart">
              <div class="dropdownSelectDate dropdownSelect">
                <div class="inner-dropdown">
                  <p>Date</p>
                  <select v-model="selectedEndDateDay">
                    <option v-for="day in days" :key="day">{{ day }}</option>
                  </select>
                </div>
              </div>
              <div class="dropdownSelectMonth dropdownSelect">
                <div class="inner-dropdown">
                  <p>Month</p>
                  <select v-model="selectedEndDateMonth">
                    <option v-for="month in months" :key="month">
                      {{ month }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="dropdownSelectYear dropdownSelect">
                <div class="inner-dropdown">
                  <p>Year</p>
                  <select v-model="selectedEndDateYear">
                    <option v-for="year in years" :key="year">
                      {{ year }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <a class="doneBtn" @click="handleEndDateDone" href="#">Done</a>
        </div>
      </div>
      <!-- <div v-if="isQuickView" class="evnetsFilterText">
        <p ref="dateRangeQuickViewRef">
          Date Range:
          <span @click="toggleDateRangeQuickViewDropdown">
            {{ selectedDateRangeQUickViewOption }}
            <i class="fa-solid fa-chevron-down"></i
          ></span>
        </p>
        <div v-if="showDateRangeQUickViewDropdown" class="newEventsDropdown">
          <ul>
            <li>
              <span @click="selectDateRangeQuickViewOption('This Week')">
                <div>This Week</div>
              </span>
            </li>
            <li>
              <span @click="selectDateRangeQuickViewOption('This Month')">
                <div>This Month</div>
              </span>
            </li>
            <li>
              <span @click="selectDateRangeQuickViewOption('Last Month')">
                <div>Last Month</div>
              </span>
            </li>
            <li>
              <span @click="selectDateRangeQuickViewOption('Year to Date')">
                <div>Year to Date</div>
              </span>
            </li>
          </ul>
        </div>
      </div> -->
      <!-- time  -->
      <div class="evnetsFilterText">
        <p ref="TimeQuickViewRef">
          Sensor Type:
          <span @click="toggleTimeQuickViewDropdown">
            {{ selectedSensorTypeValue }}
            <i class="fa-solid fa-chevron-down"></i
          ></span>
        </p>
        <div v-if="showTimeQUickViewDropdown" class="newTimeQuickViewDropdown">
          <ul>
            <li>
              <span @click="selectTimeQuickViewOption(0)">
                <div>MSD</div>
              </span>
            </li>
            <li>
              <span @click="selectTimeQuickViewOption(1)">
                <div>OSD</div>
              </span>
            </li>
            <li>
              <span @click="selectTimeQuickViewOption(2)">
                <div>All</div>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <!-- <div
        v-if="selectedTimeQUickViewOption === 'Custom'"
        class="evnetsFilterText"
      >
        <p ref="startTimeParent">
          Start Time:
          <span @click="toggleStartTimeDropdown">
            {{ selectedStartHour }}:{{ selectedStartMinute }}
            <i class="fa-solid fa-chevron-down"></i
          ></span>
        </p>
        <div class="starting-time" v-if="showStartTimeDropdown" @click.stop>
          <div class="starting-time-parent">
            <b class="starting-time1">Starting Time</b>
          </div>
          <div class="frame-parent14">
            <div class="wrapper6">
              <input
                type="text"
                style="height: 100%; border: none; padding-left: 10px"
                v-model="selectedStartHour"
              />
            </div>
            <div class="wrapper7">
              <input
                type="text"
                style="height: 100%; border: none; padding-left: 10px"
                v-model="selectedStartMinute"
              />
            </div>
            <img
              class="group-child10"
              alt=""
              src="../../assets/group-145.svg"
            />
          </div>
          <div class="done-wrapper3">
            <b class="doneBtnTime" @click="handleStartTimeDone">Done</b>
          </div>
        </div>
      </div> -->

      <!-- <div
        v-if="selectedTimeQUickViewOption === 'Custom'"
        class="evnetsFilterText"
      >
        <p ref="endTimeParent">
          End Time:
          <span @click="toggleEndTimeDropdown">
            {{ selectedEndHour }}:{{ selectedEndMinute }}
            <i class="fa-solid fa-chevron-down"></i
          ></span>
        </p>
        <div class="ending-time" v-if="showEndTimeDropdown" @click.stop>
          <div class="ending-time-parent">
            <b class="ending-time1">Ending Time</b>
          </div>
          <div class="group-div">
            <div class="wrapper4">
              <input
                type="text"
                style="height: 100%; border: none; padding-left: 10px"
                v-model="selectedEndHour"
              />
            </div>
            <div class="wrapper5">
              <input
                type="text"
                style="height: 100%; border: none; padding-left: 10px"
                v-model="selectedEndMinute"
              />
            </div>
            <img
              class="group-child10"
              alt=""
              src="../../assets/group-145.svg"
            />
          </div>
          <div class="done-wrapper3">
            <b class="doneBtnTime" @click="handleEndTimeDone">Done</b>
          </div>
        </div>
      </div> -->
      <!-- age  -->
      <!-- <div v-if="!isQuickView" class="evnetsFilterText">
        <p ref="AgeQuickViewRef">
          Age:
          <span @click="toggleAgeQuickViewDropdown">
            {{ selectedAgeQUickViewOption }}
            <i class="fa-solid fa-chevron-down"></i
          ></span>
        </p>
        <div v-if="showAgeQUickViewDropdown" class="newTimeQuickViewDropdown">
          <ul>
            <li>
              <span @click="selectAgeQuickViewOption('65 and below')">
                <div>65 and below</div>
              </span>
            </li>
            <li>
              <span @click="selectAgeQuickViewOption('Above 65')">
                <div>Above 65</div>
              </span>
            </li>
            <li>
              <span @click="selectAgeQuickViewOption('All')">
                <div>All</div>
              </span>
            </li>
          </ul>
        </div>
      </div> -->

      <div v-if="!isQuickView" class="evnetsFilterText">
        <p ref="daysParent">
          Days:
          <span @click="toggleDaysDropdown">
            {{ selectedDaysOption.join(", ") }}
            <i class="fa-solid fa-chevron-down"></i
          ></span>
        </p>
        <div class="days1" v-if="showDaysDropdown">
          <b class="days2">Days</b>
          <div class="days3">
            <p class="DaysFilterText" :class="{ 'selected-day': isSelected('Monday') }" @click="selectDaysOption('Monday')">
              Monday
            </p>
            <p class="DaysFilterText" :class="{ 'selected-day': isSelected('Tuesday') }" @click="selectDaysOption('Tuesday')">
              Tuesday
            </p>
            <p class="DaysFilterText" :class="{ 'selected-day': isSelected('Wednesday') }" @click="selectDaysOption('Wednesday')">
              Wednesday
            </p>
            <p class="DaysFilterText" :class="{ 'selected-day': isSelected('Thursday') }" @click="selectDaysOption('Thursday')">
              Thursday
            </p>
            <p class="DaysFilterText" :class="{ 'selected-day': isSelected('Friday') }" @click="selectDaysOption('Friday')">
              Friday
            </p>
            <p class="DaysFilterText" :class="{ 'selected-day': isSelected('Saturday') }" @click="selectDaysOption('Saturday')">
              Saturday
            </p>
            <p class="DaysFilterText" :class="{ 'selected-day': isSelected('Sunday') }" @click="selectDaysOption('Sunday')">
              Sunday
            </p>
          </div>

          <a class="doneDaysBtn" href="#">Done</a>
        </div>
      </div>

      <div v-if="isQuickView" class="evnetsFilterText">
        <p ref="AgeQuickViewRef">
          Age:
          <span @click="toggleAgeQuickViewDropdown">
            {{ selectedAgeQUickViewOption }}
            <i class="fa-solid fa-chevron-down"></i
          ></span>
        </p>
        <div v-if="showAgeQUickViewDropdown" class="newTimeQuickViewDropdown">
          <ul>
            <li>
              <span @click="selectAgeQuickViewOption('65 and below')">
                <div>65 and below</div>
              </span>
            </li>
            <li>
              <span @click="selectAgeQuickViewOption('Above 65')">
                <div>Above 65</div>
              </span>
            </li>
            <li>
              <span @click="selectAgeQuickViewOption('All')">
                <div>All</div>
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div v-if="isQuickView" class="evnetsFilterText">
        <p ref="daysParent">
          Days:
          <span @click="toggleDaysDropdown">
            {{ selectedDaysOption.join(", ") }}
            <i class="fa-solid fa-chevron-down"></i
          ></span>
        </p>
        <div class="days1" v-if="showDaysDropdown">
          <b class="days2">Days</b>
          <div class="days3">
            <p class="DaysFilterText" :class="{ 'selected-day': isSelected('Monday') }" @click="selectDaysOption('Monday')">
              Monday
            </p>
            <p class="DaysFilterText" :class="{ 'selected-day': isSelected('Tuesday') }" @click="selectDaysOption('Tuesday')">
              Tuesday
            </p>
            <p class="DaysFilterText" :class="{ 'selected-day': isSelected('Wednesday') }" @click="selectDaysOption('Wednesday')">
              Wednesday
            </p>
            <p class="DaysFilterText" :class="{ 'selected-day': isSelected('Thursday') }" @click="selectDaysOption('Thursday')">
              Thursday
            </p>
            <p class="DaysFilterText" :class="{ 'selected-day': isSelected('Friday') }" @click="selectDaysOption('Friday')">
              Friday
            </p>
            <p class="DaysFilterText" :class="{ 'selected-day': isSelected('Saturday') }" @click="selectDaysOption('Saturday')">
              Saturday
            </p>
            <p class="DaysFilterText" :class="{ 'selected-day': isSelected('Sunday') }" @click="selectDaysOption('Sunday')">
              Sunday
            </p>
          </div>

          <a class="doneDaysBtn" href="#">Done</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import group from "../../assets/group.svg";

export default {
  props: {
    isQuickView: Boolean,
  },
  data() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear() % 100;
    const currentMonth = currentDate.getMonth();

    const daysInMonth = new Date(
      currentDate.getFullYear(),
      currentMonth + 1,
      0
    ).getDate();
    const monthDays = Array.from({ length: daysInMonth }, (_, i) =>
      (i + 1).toString()
    );
    const months = Array.from({ length: 12 }, (_, i) => {
      const monthDate = new Date(currentDate.getFullYear(), i, 1);
      const monthName = monthDate.toLocaleString("en-US", { month: "long" });
      return monthName;
    });

    const years = Array.from({ length: 13 }, (_, i) =>
      (currentYear - 10 + i).toString()
    );

    return {
      group,
      showParkingsDropdown: false,
      selectedOption: "No Placard",
      showDaysDropdown: false,
      selectedDaysOption: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      showStateDropdown: false,
      selectedStateOption: "Texas",
      selectedDate: currentDate.getDate().toString(),
      selectedMonth: months[currentMonth],
      selectedYear: currentYear.toString(),
      selectedStartDate: this.getCurrentDate(),
      showDateDropdown: false,
      days: monthDays,
      months: months,
      years: years,
      showEndDateDropdown: false,
      selectedEndDate: this.getCurrentDate(),
      selectedEndDateDay: currentDate.getDate().toString(),
      selectedEndDateMonth: months[currentMonth],
      selectedEndDateYear: currentYear.toString(),
      showStartTimeDropdown: false,
      selectedStartHour: "22",
      selectedStartMinute: "30",
      showEndTimeDropdown: false,
      selectedEndHour: "22", // Initial value for hour
      selectedEndMinute: "30",
      showAgeDropdown: false,
      selectedAge: "2 weeks",
      selectedAgeValue: 20,
      filterOptions: [
        { value: "Parking", label: "Parking" },
        { value: "Placard", label: "Placard" },
        { value: "Misuse", label: "Misuse" },
        { value: "Theft", label: "Theft" },
      ],
      showDrawer: false,
      selectedFilters: [],
      showDateRangeQUickViewDropdown: false,
      selectedDateRangeQUickViewOption: "This Month",
      showTimeQUickViewDropdown: false,
      selectedTimeQUickViewOption: 2,
      showAgeQUickViewDropdown: false,
      selectedAgeQUickViewOption: "65 and below",
    };
  },
  computed: {
    selectedSensorTypeValue() {
      const sensors = ["MSD", "OSD", "ALL"];
      return sensors[this.selectedTimeQUickViewOption];
    },
  },
  methods: {
    // ... (other methods)
    // Toggle the visibility of the drawer
    isOptionSelected(option) {
      return this.selectedFilters.includes(option);
    },
    isSelected(day) {
      return this.selectedDaysOption.includes(day);
    },
    // Method to handle checkbox click
    handleCheckboxClick(option) {
      if (this.selectedFilters.includes(option)) {
        // Option is already selected, remove it
        this.selectedFilters = this.selectedFilters.filter(
          (item) => item !== option
        );
      } else {
        // Option is not selected, add it
        this.selectedFilters.push(option);
      }
    },
    toggleDrawer() {
      this.showDrawer = !this.showDrawer;
    },
    // Close the drawer
    closeDrawer() {
      this.showDrawer = false;
    },
    toggleAgeDropdown() {
      this.showAgeDropdown = !this.showAgeDropdown;

      if (this.showAgeDropdown) {
        // Add a global click event listener to close the dropdown when clicking outside
        window.addEventListener("click", this.handleAgeGlobalClick);
      } else {
        // Remove the click event listener when the dropdown is closed
        window.removeEventListener("click", this.handleAgeGlobalClick);
      }
    },
    checkTarget(event) {
      // Stop propagation only if the target is not the range input
      if (event.target.tagName.toLowerCase() !== "input") {
        event.stopPropagation();
      }
    },
    handleAgeDone() {
      this.showAgeDropdown = false;
      // Add any additional logic you need with the selectedAgeValue
      window.removeEventListener("click", this.handleAgeGlobalClick);
    },
    handleAgeGlobalClick(event) {
      // Check if the click is outside the age-parent
      if (!this.$refs.ageParent?.contains(event.target)) {
        this.showAgeDropdown = false;
        window.removeEventListener("click", this.handleAgeGlobalClick);
      }
    },
    toggleEndTimeDropdown() {
      this.showEndTimeDropdown = !this.showEndTimeDropdown;
      if (this.showEndTimeDropdown) {
        // Add a global click event listener to close the dropdown when clicking outside
        window.addEventListener("click", this.handleEndTimeGlobalClick);
      } else {
        // Remove the click event listener when the dropdown is closed
        window.removeEventListener("click", this.handleEndTimeGlobalClick);
      }
    },
    handleEndTimeDone() {
      // Update your data or perform any action with the formatted end time
      this.showEndTimeDropdown = false;
      window.removeEventListener("click", this.handleEndTimeGlobalClick);
    },
    handleEndTimeGlobalClick(event) {
      // Check if the click is outside the end-time-parent
      if (!this.$refs.endTimeParent?.contains(event.target)) {
        this.showEndTimeDropdown = false;
        window.removeEventListener("click", this.handleEndTimeGlobalClick);
      }
    },
    toggleStartTimeDropdown() {
      this.showStartTimeDropdown = !this.showStartTimeDropdown;
      if (this.showStartTimeDropdown) {
        // Add a global click event listener to close the dropdown when clicking outside
        window.addEventListener("click", this.handleStartTimeGlobalClick);
      } else {
        // Remove the click event listener when the dropdown is closed
        window.removeEventListener("click", this.handleStartTimeGlobalClick);
      }
    },
    handleStartTimeDone() {
      // Save or display the formattedStartTime as needed

      this.showStartTimeDropdown = false;
      window.removeEventListener("click", this.handleStartTimeGlobalClick);
    },
    handleStartTimeGlobalClick(event) {
      // Check if the click is outside the start-time-parent
      if (!this.$refs.startTimeParent?.contains(event.target)) {
        this.showStartTimeDropdown = false;
        window.removeEventListener("click", this.handleStartTimeGlobalClick);
      }
    },
    toggleEndDateDropdown() {
      this.showEndDateDropdown = !this.showEndDateDropdown;

      if (this.showEndDateDropdown) {
        // Add a global click event listener to close the dropdown when clicking outside
        window.addEventListener("click", this.handleEndDateGlobalClick);
      } else {
        // Remove the click event listener when the dropdown is closed
        window.removeEventListener("click", this.handleEndDateGlobalClick);
      }
    },
    handleEndDateDone() {
      this.selectedEndDate = `${this.getMonthNumber(
        this.selectedEndDateMonth
      )}/${this.selectedEndDateDay}/${this.selectedEndDateYear}`;
      this.$emit("endDateChanged", this.selectedEndDate);
      this.showEndDateDropdown = false;
      window.removeEventListener("click", this.handleEndDateGlobalClick);
    },
    handleEndDateGlobalClick(event) {
      // Check if the click is outside the date-end-parent
      if (!this.$refs.dateEndParent?.contains(event.target)) {
        this.showEndDateDropdown = false;
        window.removeEventListener("click", this.handleEndDateGlobalClick);
      }
    },
    getCurrentDate() {
      const currentDate = new Date();
      const day = currentDate.getDate();
      const month = currentDate.getMonth() + 1; // Months are 0-indexed
      const year = currentDate.getFullYear() % 100; // Use the last two digits of the year

      // Format the date as "MM/DD/YY"
      return `${month}/${day}/${year}`;
    },
    toggleDateDropdown() {
      this.showDateDropdown = !this.showDateDropdown;

      if (this.showDateDropdown) {
        // Add a global click event listener to close the dropdown when clicking outside
        window.addEventListener("click", this.handleDateGlobalClick);
      } else {
        // Remove the click event listener when the dropdown is closed
        window.removeEventListener("click", this.handleDateGlobalClick);
      }
    },
    handleDateDone() {
      this.selectedStartDate = `${this.getMonthNumber(this.selectedMonth)}/${
        this.selectedDate
      }/${this.selectedYear}`;
      this.$emit("StartDateChanged", this.selectedStartDate);
      this.showDateDropdown = false;
      window.removeEventListener("click", this.handleDateGlobalClick);
    },
    handleDateGlobalClick(event) {
      // Check if the click is outside the date-start-parent
      if (!this.$refs.dateStartParent?.contains(event.target)) {
        this.showDateDropdown = false;
        window.removeEventListener("click", this.handleDateGlobalClick);
      }
    },
    getMonthNumber(month) {
      // Convert month name to its numerical representation
      const monthsMap = {
        January: "01",
        February: "02",
        March: "03",
        April: "04",
        May: "05",
        June: "06",
        July: "07",
        August: "08",
        September: "09",
        October: "10",
        November: "11",
        December: "12",
      };
      return monthsMap[month];
    },
    toggleAgeQuickViewDropdown() {
      this.showAgeQUickViewDropdown = !this.showAgeQUickViewDropdown;

      if (this.showAgeQUickViewDropdown) {
        // Add a global click event listener to close the dropdown when clicking outside
        window.addEventListener("click", this.handleAgeQuickViewGlobalClick);
      } else {
        // Remove the click event listener when the dropdown is closed
        window.removeEventListener("click", this.handleAgeQuickViewGlobalClick);
      }
    },
    handleAgeQuickViewGlobalClick(event) {
      // Check if the click is outside the state-parent
      if (!this.$refs.AgeQuickViewRef?.contains(event.target)) {
        this.showAgeQUickViewDropdown = false;
        window.removeEventListener("click", this.handleAgeQuickViewGlobalClick);
      }
    },
    selectAgeQuickViewOption(option) {
      this.selectedAgeQUickViewOption = option;
      this.showAgeQUickViewDropdown = false;
      window.removeEventListener("click", this.handleAgeQuickViewGlobalClick);
    },
    toggleTimeQuickViewDropdown() {
      this.showTimeQUickViewDropdown = !this.showTimeQUickViewDropdown;

      if (this.showTimeQUickViewDropdown) {
        // Add a global click event listener to close the dropdown when clicking outside
        window.addEventListener("click", this.handleTimeQuickViewGlobalClick);
      } else {
        // Remove the click event listener when the dropdown is closed
        window.removeEventListener(
          "click",
          this.handleTimeQuickViewGlobalClick
        );
      }
    },
    handleTimeQuickViewGlobalClick(event) {
      // Check if the click is outside the state-parent
      if (!this.$refs?.TimeQuickViewRef?.contains(event.target)) {
        this.showTimeQUickViewDropdown = false;
        window.removeEventListener(
          "click",
          this.handleTimeQuickViewGlobalClick
        );
      }
    },
    selectTimeQuickViewOption(option) {
      this.selectedTimeQUickViewOption = option;
      this.showTimeQUickViewDropdown = false;
      window.removeEventListener("click", this.handleTimeQuickViewGlobalClick);
      this.$emit("sensorTypeChanged", this.selectedTimeQUickViewOption);
    },
    toggleDateRangeQuickViewDropdown() {
      this.showDateRangeQUickViewDropdown =
        !this.showDateRangeQUickViewDropdown;

      if (this.showDateRangeQUickViewDropdown) {
        // Add a global click event listener to close the dropdown when clicking outside
        window.addEventListener(
          "click",
          this.handleDateRangeQuickViewGlobalClick
        );
      } else {
        // Remove the click event listener when the dropdown is closed
        window.removeEventListener(
          "click",
          this.handleDateRangeQuickViewGlobalClick
        );
      }
    },
    handleDateRangeQuickViewGlobalClick(event) {
      // Check if the click is outside the state-parent
      if (!this.$refs.dateRangeQuickViewRef?.contains(event.target)) {
        this.showDateRangeQUickViewDropdown = false;
        window.removeEventListener(
          "click",
          this.handleDateRangeQuickViewGlobalClick
        );
      }
    },
    selectDateRangeQuickViewOption(option) {
      this.selectedDateRangeQUickViewOption = option;
      this.showDateRangeQUickViewDropdown = false;
      window.removeEventListener(
        "click",
        this.handleDateRangeQuickViewGlobalClick
      );
    },
    toggleStateDropdown() {
      this.showStateDropdown = !this.showStateDropdown;

      if (this.showStateDropdown) {
        // Add a global click event listener to close the dropdown when clicking outside
        window.addEventListener("click", this.handleStateGlobalClick);
      } else {
        // Remove the click event listener when the dropdown is closed
        window.removeEventListener("click", this.handleStateGlobalClick);
      }
    },
    selectStateOption(option) {
      this.selectedStateOption = option;
      this.showStateDropdown = false;
      window.removeEventListener("click", this.handleStateGlobalClick);
    },
    handleStateGlobalClick(event) {
      // Check if the click is outside the state-parent
      if (!this.$refs.stateParent?.contains(event.target)) {
        this.showStateDropdown = false;
        window.removeEventListener("click", this.handleStateGlobalClick);
      }
    },
    toggleDaysDropdown() {
      this.showDaysDropdown = !this.showDaysDropdown;

      if (this.showDaysDropdown) {
        // Add a global click event listener to close the dropdown when clicking outside
        window.addEventListener("click", this.handleDaysGlobalClick);
      } else {
        // Remove the click event listener when the dropdown is closed
        window.removeEventListener("click", this.handleDaysGlobalClick);
      }
    },
    selectDaysOption(day) {
      if (this.selectedDaysOption.includes(day)) {
        // Remove day if already selected
        this.selectedDaysOption = this.selectedDaysOption.filter(
          (selectedDay) => selectedDay !== day
        );
      } else {
        // Add day if not selected
        this.selectedDaysOption.push(day);
      }
      this.$emit("daysFilterChanged", this.selectedDaysOption);
    },
    handleDaysGlobalClick(event) {
      // Check if the click is outside the days-parent
      if (!this.$refs.daysParent?.contains(event.target)) {
        this.showDaysDropdown = false;
        window.removeEventListener("click", this.handleDaysGlobalClick);
      }
    },
    toggleDropdown() {
      this.showParkingsDropdown = !this.showParkingsDropdown;

      if (this.showParkingsDropdown) {
        // Add a global click event listener to close the dropdown when clicking outside
        window.addEventListener("click", this.handleGlobalClick);
      } else {
        // Remove the click event listener when the dropdown is closed
        window.removeEventListener("click", this.handleGlobalClick);
      }
    },
    selectOption(option) {
      this.selectedOption = option;
      this.showParkingsDropdown = false;
      window.removeEventListener("click", this.handleGlobalClick);
      this.$emit("eventTypeChanged", this.selectedOption);
    },
    handleGlobalClick(event) {
      // Check if the click is outside the events-parent
      if (!this.$refs?.eventsParent?.contains(event.target)) {
        this.showParkingsDropdown = false;
        window.removeEventListener("click", this.handleGlobalClick);
      }
    },
  },
  mounted() {
    this.$emit("endDateChanged", this.selectedEndDate);
    this.$emit("startDateChanged", this.selectedStartDate);
    this.$emit("eventTypeChanged", this.selectedOption);
    this.$emit("sensorTypeChanged", this.selectedTimeQUickViewOption);
    this.$emit("daysFilterChanged", this.selectedDaysOption);
  },
};
</script>

<style scoped>
.filterContainer {
  position: absolute;
}
.selected-day {
  background-color: blue;
  border-radius: 8px;
  color: white; /* Add appropriate text color for better visibility */
  padding: 5px 10px;
  cursor: pointer;
}
/* Add styles for the drawer/modal */
.drawer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  color: white;
  /* padding: 20px; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 99999999;
}

/* Style the close button */
.close-button {
  cursor: pointer;
  margin-top: 10px;
  /* padding: 10px; */
  /* background-color: #fff; */
  /* color: #333; */
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  /* background-color: #fff; */
  /* color: #333; */
  border-radius: 5px;
  color: white;
  padding-right: 32px;
  font-family: Jost;
  font-size: 21.955px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-size: 21.955px;
}

.drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 105.385px;
  width: 100%;
  background: #122e60;
  color: white;
  padding: 0px 10px;
}
.drawer-header .h3 {
  font-family: Jost;
  font-size: 21.955px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-size: 21.955px;
}
.selected {
}
/* Style the close button */

/* Style the card */

/* Add your component-specific styles here if needed */
/* Add styles based on the provided class names in the original React component */
</style>
