import { initializeApp } from 'firebase/app';
import { getDatabase, ref } from 'firebase/database';
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyCrDl5cQfRcNL5vnnPgbIzuLTizIyikrGo",
  authDomain: "parkingnsite.firebaseapp.com",
  databaseURL: "https://parkingnsite-default-rtdb.firebaseio.com",
  projectId: "parkingnsite",
  storageBucket: "parkingnsite.appspot.com",
  messagingSenderId: "249935323973",
  appId: "1:249935323973:web:f2845d9a1a3a665975d42c",
  measurementId: "G-KEWMP627C1"
};


// const firebaseConfig = {
//   apiKey: "AIzaSyDS4leh0XG6g2oAtbRUN2jst3HmkKqIwG4",
//   authDomain: "parkingnsite-6b561.firebaseapp.com",
//   projectId: "parkingnsite-6b561",
//   storageBucket: "parkingnsite-6b561.appspot.com",
//   messagingSenderId: "503793018817",
//   appId: "1:503793018817:web:e37ac6ebef3bd99f963140",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize database
const database = getDatabase(app);

const firestore = getFirestore();

export { database, firestore, ref as dbRef, };
