/* eslint-disable no-unused-vars */
import { database } from "@/firebase"; // Replace with your Firebase initialization file
import { ref, onValue, update, remove } from "firebase/database";
import moment from "moment";

const FirebasePath = "Dashboard";

class FirebaseService {
  allUsers = [];
  violationTrips = [];
  allTrips = [];

  async getAllUsernames() {
    if (!this.allUsers.length) {
      try {
        const usersRef = ref(database, `${FirebasePath}/Users`);
        return new Promise((resolve, reject) => {
          onValue(
            usersRef,
            (snapshot) => {
              const data = snapshot.val();

              const response = Object.entries(data).map((item) => ({
                id: item[0],
                ...item[1],
              }));

              this.allUsers = response;
              resolve(response);
            },
            (error) => {
              console.error(error.message || error);
              reject(error);
            }
          );
        });
      } catch (error) {
        console.error(error.message || error);
      }
    } else {
      return Promise.resolve(this.allUsers);
    }
  }

  async deleteTripById(userId, year, month, day, tripId) {
    const tripRef = ref(
      database,
      `${FirebasePath}/Users/${userId}/Trips/${year}/${month}/${day}/${tripId}`
    );

    remove(tripRef)
      .then(() => {
        console.log("Trip deleted successfully");
      })
      .catch((error) => {
        console.error("Error deleting trip:", error);
      });
  }

  async updateAppSettings(key, value) {
    const appSettingsRef = ref(database, "App_Settings");
    // const valuesToUpdate = {
    //   BASE_RESET: "",
    //   BASE_SLWait: "",
    //   BASE_resetbuffer: "",
    //   BASE_slbuffer: "",
    // };
    const payload = {};
    payload[key] = value;
    update(appSettingsRef, payload).catch((error) => {
      console.error("Error updating data:", error);
    });
  }

  // 1-  "100458d03aedc2e7"
  // 7 - "6bd313338c4b7aba"

  async getDashboardData(startDate, endDate) {
    const dashboardRef = ref(database, FirebasePath);
    return new Promise((resolve, reject) => {
      onValue(
        dashboardRef,
        (snapshot) => {
          const data = snapshot.val();
          this.violationTrips = Object.entries(data.Violation_Trips).map(
            ([id, obj]) => ({ id, ...obj })
          );
          this.allUsers = Object.entries(data.Users).map(([id, obj]) => ({
            id,
            ...obj,
          }));

          const totalTrips = [];
          this.allUsers.forEach((userObj) => {
            if (userObj.Trips) {
              const trips = this.TripsFromUserObject(
                userObj,
                startDate,
                endDate
              );
              totalTrips.push(...trips);
            }
          });
          this.allTrips = totalTrips;

          resolve({
            totalTrips: this.allTrips,
            totalTripsCount: this.getTotalTrips(),
            abandonedTrips: data.abandoned_trips,
            violationTrips: this.violationTrips,
          });
        },
        (error) => {
          console.error(error.message || error);
          reject(error);
        }
      );
    });
  }

  TripsFromUserObject(userObj, startDate, endDate) {
    const tripsInRange = [];
    if (userObj && userObj.Trips) {
      if (startDate && endDate) {
        const startMoment = moment(startDate, "MM/DD/YYYY");
        const endMoment = moment(endDate, "MM/DD/YYYY");

        let currentMoment = startMoment.clone();
        const endDateMoment = endMoment.clone();

        while (currentMoment.isSameOrBefore(endDateMoment)) {
          const year = currentMoment.year();
          let month = currentMoment.month() + 1;
          let day = currentMoment.date();
          if (month < 10) month = `0${+month}`;
          if (day < 10) day = `0${+day}`;

          let trip = null;

          if (
            userObj.Trips[year] &&
            userObj.Trips[year][month] &&
            userObj.Trips[year][month][day]
          ) {
            trip = userObj.Trips[year][month][day];
          }

          if (trip) {
            tripsInRange.push(
              ...Object.entries(trip).map((item) => ({
                id: item[0],
                year,
                month,
                day,
                ...item[1],
              }))
            );
          }

          currentMoment.add(1, "day");
        }
      }
    }
    return tripsInRange;
  }

  getTotalTrips() {
    return this.allTrips.length;
  }
}

export default new FirebaseService();
