<template>
  <div style="position: relative" class="roundChart">
    <canvas ref="chartCanvas"></canvas>
    <!-- <p
      class="percentage"
      style="
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 17px;
      "
    >
      34%
    </p> -->
  </div>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default {
  props: {
    tripsWithNoWait: Number,
    tripsWithWait: Number,
  },
  setup(props) {
    const chartCanvas = ref(null);
    let chart = null;

    const chartData = computed(() => {
      if (!props.tripsWithNoWait && !props.tripsWithWait) {
        return [50, 50];
      }
      return [props.tripsWithNoWait || 0, props.tripsWithWait || 0];
    });

    onMounted(() => {
      const ctx = chartCanvas.value.getContext("2d");
      chart = new Chart(ctx, {
        type: "doughnut",
        data: {
          datasets: [
            {
              data: chartData.value,
              backgroundColor: ["#006AA7", "#94D4FA"],
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          cutoutPercentage: 80,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: false,
          },
          hover: {
            mode: false,
          },
        },
      });
    });

    watch(chartData, (newData) => {
      if (chart) {
        chart.data.datasets[0].data = newData;
        chart.update();
      }
    });

    return { chartCanvas };
  },
};
</script>
<style scoped>
div {
  display: block;
  background-color: rgb(255, 255, 255);
  width: 100px;
  height: 100px;
}
</style>
