import { createApp } from "vue";
import App from "./App.vue";
import "./App.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "jquery";

import FirebaseService from "./services/FirebaseService";
import AppService from "./services/AppService";

const app = createApp(App);
app.provide('firestore', FirebaseService);
app.provide('AppService', AppService);
app.mount("#app");
