<template>
  <!-- <div id="app">
    <Main initialValue="someValue" />
  </div> -->

<div id="app">
    <div v-if="isAuthenticated">
      <!-- <div v-if="true"> -->
      <Main initialValue="someValue" />
      <!-- <Main /> Protected Component -->
    </div>
    <div v-else>
      <p>Authenticating...</p>
      <!-- You can use a router-link or an HTML link for redirection -->
      <!-- <a href="https://parkingnsites.com/">Go to Login Page</a> -->
    </div>
  </div>

  <meta charset="utf-8" />
  <meta name="viewport" content="initial-scale=1, width=device-width" />
  <title>Dashboard</title>

  <meta name="description" content="Parking Site Dashboard" />
  <link rel="stylesheet" href="./css/global.css" />
  <link rel="stylesheet" href="./css/index.css" />
  <link rel="stylesheet" href="./css/events-dropdown.css" />
  <link
    rel="stylesheet"
    href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"
  />

  <link rel="preconnect" href="https://fonts.googleapis.com" />
  <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
  <link href="https://fonts.googleapis.com/css?family=Jost" rel="stylesheet" />

  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css"
    integrity="sha512-..."
    crossorigin="anonymous"
    referrerpolicy="no-referrer"
  />
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css"
    integrity="sha512-z3gLpd7yknf1YoNbCzqRKc4qyor8gaKU1qmn+CShxbuBusANI9QpRohGBreCFkKxLhei6S9CQXFEbbKuqLg0DA=="
    crossorigin="anonymous"
    referrerpolicy="no-referrer"
  />
</template>

<script>
import Main from "@/components/DashboardLayout.vue";
import { isAuthenticated } from "../src/utils/auth.js";

export default {
  components: {
    Main,
  },

  data() {
    return {
      // isAuthenticated: isAuthenticated(),
      // isAuthenticated: isAuthenticated().authToken, // Use hardcoded check
      // isAuthenticated: await isAuthenticated(), // Use hardcoded check
       isAuthenticated: false,  // Default value
      someValue: "Hello from App component!",
    };
  },
  // async created() {
  //   this.isAuthenticated = await isAuthenticated();
  //   if (!this.isAuthenticated) {
  //     window.location.href = "https://parkingnsites.com/";
  //   }
  // },
  async mounted() {
    try {
      this.isAuthenticated = await isAuthenticated();
      if (!this.isAuthenticated) {
        window.location.href = "https://parkingnsites.com/";
      }
    } catch (error) {
      console.error('Error during authentication check:', error );
      window.location.href = "https://parkingnsites.com/";
    }
  },

  // data() {
  //   return {
  //     someValue: "Hello from App component!",
  //   };
  // },

  // mounted() {
  //   // Include jQuery from CDN
  // },
};
</script>

<style>
/* Add your global styles here if needed */
</style>
