<template>
  <div class="modal-overlay" @click="closeModal">
    <div class="modal-content" @click.stop>
      <p>Type: {{ type }}</p>
      <p>Address: {{ address }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: String, // Prop for the type text
    address: String, // Prop for the address text
  },
  methods: {
    closeModal() {
      this.$emit('close'); // Emit 'close' event to parent component
    }
  },
};
</script>

<style scoped>
.modal-overlay {
  z-index: 9000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}
</style>
